// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bread-timer-js": () => import("./../../../src/pages/bread-timer.js" /* webpackChunkName: "component---src-pages-bread-timer-js" */),
  "component---src-pages-covid-counter-js": () => import("./../../../src/pages/covid-counter.js" /* webpackChunkName: "component---src-pages-covid-counter-js" */),
  "component---src-pages-goodbudget-js": () => import("./../../../src/pages/goodbudget.js" /* webpackChunkName: "component---src-pages-goodbudget-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-roast-dinner-js": () => import("./../../../src/pages/roast-dinner.js" /* webpackChunkName: "component---src-pages-roast-dinner-js" */)
}

